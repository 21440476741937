import React from "react"
import { graphql } from "gatsby"
import FlexibleLayout from "../components/DesignSystem/FlexibleLayout"
import { StateContext } from "../components/PageLayout/StateContext"
import { motion } from "framer-motion"
import Facebook from "../../assets/facebook.svg"
import Linkedin from "../../assets/linkedin.svg"

export const query = graphql`
  query postQuery($postId: String!) {
    wpPost(id: { eq: $postId }) {
      id
      title
      ACFtags {
        appliedTags
      }
      layout {
        pageMarginTop
        designSystem {
          ... on WpPost_Layout_DesignSystem_Slideshow {
            fieldGroupName
            visible
            slides {
              text
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPost_Layout_DesignSystem_LinkBlocks {
            fieldGroupName
            visible
            blocks {
              pageLink {
                ... on WpPage {
                  id
                  uri
                  title
                }
              }
              text
              movingText
              backgroundColor
            }
          }
          ... on WpPost_Layout_DesignSystem_TextCenteredHighlighted {
            fieldGroupName
            visible
            text
          }
          ... on WpPost_Layout_DesignSystem_QuickLinksList {
            fieldGroupName
            visible
            links {
              ... on WpPage {
                id
                uri
                title
              }
            }
          }
          ... on WpPost_Layout_DesignSystem_ArticleTitle {
            fieldGroupName
            visible
            title
          }
          ... on WpPost_Layout_DesignSystem_Occhiello {
            fieldGroupName
            text
            visible
          }
          ... on WpPost_Layout_DesignSystem_Quote {
            fieldGroupName
            text
            visible
          }
          ... on WpPost_Layout_DesignSystem_Paragraph {
            fieldGroupName
            text
            visible
          }
          ... on WpPost_Layout_DesignSystem_EventsGrid {
            fieldGroupName
            visible
          }
          ... on WpPost_Layout_DesignSystem_PublicationsGrid {
            fieldGroupName
            visible
            howMany
            selection {
              ... on WpPost {
                id
                uri
                title
                pubblicazione {
                  anno
                }
              }
            }
          }
          ... on WpPost_Layout_DesignSystem_EventsSticky {
            fieldGroupName
            visible
          }
          ... on WpPost_Layout_DesignSystem_PublicationsSticky {
            fieldGroupName
            visible
          }
          ... on WpPost_Layout_DesignSystem_ImageBanner {
            fieldGroupName
            visible
            dida
            fullBleed
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPost_Layout_DesignSystem_GifBanner {
            visible
            fieldGroupName
            fullBleed
            image {
              localFile {
                url
              }
            }
            dida
          }
          ... on WpPost_Layout_DesignSystem_VideoBanner {
            visible
            fieldGroupName
            showControls
            videoVimeoId
            dida
          }
          ... on WpPost_Layout_DesignSystem_TagsLinks {
            fieldGroupName
            visible
          }
          ... on WpPost_Layout_DesignSystem_FlexibleRow {
            fieldGroupName
            visible
            type
            image {
              imageOrGif
              dida
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              gif {
                localFile {
                  url
                }
              }
            }
            slides {
              dida
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPost_Layout_DesignSystem_HeroSection {
            fieldGroupName
            visible
            text
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPost_Layout_DesignSystem_PubblicazioneInfo {
            fieldGroupName
            visible
            info {
              label
              value
            }
            copertina {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPost_Layout_DesignSystem_SingleEventLink {
            fieldGroupName
            visible
            event {
              ... on WpPost {
                id
                excerpt
                title
                uri
                translated {
                  translated {
                    localizedWpmlUrl
                    uri
                    excerpt
                    title
                  }
                }
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPost_Layout_DesignSystem_AccordionsGroup {
            fieldGroupName
            visible
            accordion {
              content
              label
            }
          }
          ... on WpPost_Layout_DesignSystem_PublicationsRelated {
            fieldGroupName
            visible
            related {
              ... on WpPost {
                id
                uri
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                pubblicazione {
                  anno
                }
              }
            }
          }
          ... on WpPost_Layout_DesignSystem_ButtonsGroup {
            fieldGroupName
            visible
            button {
              label
              type
              link
              file {
                publicUrl
              }
            }
          }
        }
      }
    }
  }
`

const PostTemplate = ({ data, location, menuState }) => {
  return (
    <>
      <FlexibleLayout
        layout={data.wpPost.layout?.designSystem}
        pageMarginTop={data.wpPost.layout?.pageMarginTop}
        menuState={menuState}
        location={location}
      />
      {StateContext.width > 1015 &&
        data.wpPost.ACFtags.appliedTags !== null && (
          <TagsChips tags={data.wpPost.ACFtags.appliedTags.split(" ")} />
        )}
    </>
  )
}

export default PostTemplate

const moves = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 0,
  },
}
const TagsChips = ({ tags }) => {
  return (
    <motion.div
      variants={moves}
      initial="initial"
      animate={StateContext.isScrolled ? "animate" : "initial"}
      className="text-dkGray fixed top-0 right-10 z-[1]"
      style={{
        marginTop:
          StateContext.width > 1015
            ? 300
            : StateContext.width < 500
            ? 130
            : 115 + 50 + "px",
      }}
    >
      <div className="flex flex-col items-start gap-y-2 mb-6">
        <button className=" rounded-full hover:shadow-xl duration-300">
          <Facebook width={35} height={35} />
        </button>
        <button className="rounded-full hover:shadow-xl duration-300">
          <Linkedin width={35} height={35} />
        </button>
      </div>
      <div className="flex flex-col leading-[1.4] text-xs">
        TAG
        {tags.map((tag, index) => {
          return <div key={`tag-${index}`}>{tag}</div>
        })}
      </div>
    </motion.div>
  )
}
